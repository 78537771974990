// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

video {
    width: 100%;
}

h1, h2, h3, h4, h5, h6, .site-title {
    font-family:      "Roboto Mono", Consolas, sans-serif;
}

.site-nav {
    text-align: left;
    line-height: 1em;
}

.site-header {
    padding-bottom: 20px;
    border-top: none;
}

.masonry {
    column-count: 4;
    column-gap: 20px;

    .item {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;

        h3 {
            text-align: center;
            font-size: 1em;
            color: $text-color;
            margin-bottom: 0px;
        }

        .thumbnail {
            border-radius: 15px;
            background-color: rgba(0, 0, 0, 0.3);
            padding: 10px;
        }

        img {
            border-radius: 5px;
        }

        &:hover .thumbnail {
            background-color: black;
        }
    }
}

.col-3 {
    column-count: 3;
}

.col-2 {
    column-count: 2;
}

.home .masonry {
    margin-bottom: 40px;
}

form {
    input, textarea, button {
        box-sizing: border-box; 
        background-color: rgba(0, 0, 0, 0.13);
        border: 1px rgba(0, 0, 0, 0.20) solid;
        border-radius: 3px;
        color: $text-color;
        font-size: 1em;
        padding: 5px;
        margin: 3px 0px 10px 0px;

        &:focus {
            background-color: rgba(0, 0, 0, 0.20);
            border: 1px black solid;
        }
    }

    label {
        font-size: 1em;
    }

    input {
        width: 100%;
    }

    textarea {
        width: 100%;
        height: 150px;
    }

    div {
        display: inline-block;
    }

    button {
        background-color: lighten($background-color, 4%);
        border: 1px rgba(255, 255, 255, 0.03) solid;
        box-shadow: 0 0 3px black;
        float: right;
        padding: 8px 16px;
        font-family: "Roboto Mono", Consolas, sans-serif;
    }
}

#stl_cont {
    background-color: rgba(0, 0, 0, 0.13);
    border: 1px rgba(0, 0, 0, 0.20) solid;
    border-radius: 5px;
    margin-bottom: 15px;
}